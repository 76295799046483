import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addRole, getUser, removeRole } from "../api/dashboardAPI";
import { X } from "react-bootstrap-icons";
import Loader from "../components/Loader";

const SingleUser = (props) => {
  const { token } = useSelector((state) => state.user);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [roles, setRoles] = useState();
  const [disabled, setDisabled] = useState(false);

  const { idUser } = useParams();

  useEffect(() => {
    getUser(token, idUser).then((res) => {
      setUser(res.data);
      setRoles(res.data.role);
      setLoading(false);
    });
  }, []);

  const handleClick = () => {
    if (!roles.find((el) => el === type)) {
      setDisabled(true);
      const body = { user_id: idUser, role: type };
      addRole(token, body)
        .then(() => {
          setRoles((prev) => [...prev, type]);
          setDisabled(false);
        })
        .catch(() => setDisabled(false));
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div>
        <div className="title">{user.name}</div>
        <p style={{ marginBottom: "2rem" }}>{user.email} </p>
        <p style={{ marginBottom: "3rem" }}>L'utente si è registrato come: {user.signup_type_user ?? "l'utente non ha espresso una preferenza"} </p>

        <p style={{ marginBottom: "0.5rem" }}>Ruoli Utente:</p>
        <div className="tags-container">
          {roles.map((role, index) => {
            return (
              <div className="tag" key={index}>
                {role}
                <button
                  className="remove-btn"
                  onClick={() =>
                    removeRole(token, { user_id: idUser, role }).then(
                      setRoles((prev) => {
                        return prev.filter((el) => el !== role);
                      })
                    )
                  }
                >
                  {role !== "Admin" && role !== "User" && <X />}
                </button>
              </div>
            );
          })}
        </div>

        <div className="form-container">
          <form className="tag-form">
            <div className="tag-category">
              <label> Aggiungi Ruolo</label>
              <select value={type} onChange={(e) => setType(e.target.value)}>
                <option value="" disabled hidden>
                  Seleziona un ruolo
                </option>
                {["ExternalValidator", "Validator"].map((role) => (
                  <option value={role}>{role}</option>
                ))}
              </select>
            </div>
          </form>
          <button className="btn" disabled={disabled} onClick={handleClick}>
            Aggiungi
          </button>
        </div>
      </div>
    </>
  );
};
export default SingleUser;
